 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="110px">
			<el-form-item class="el_form_item" label="注册人手机">
				<el-input class="el_input" v-model="form.tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="注册身份证">
				<el-input class="el_input" v-model="form.id_card_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="公司名称">
				<el-input class="el_input" v-model="form.name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="营业执照号码">
				<el-input class="el_input" v-model="form.license_code" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="审核状态">
				<el-select class="el_input" v-model="form.check_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="待审核" value="1"></el-option>
					<el-option label="已通过" value="2"></el-option>
					<el-option label="未通过" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="ser">查询</el-button>
				<!-- <el-button type="success" @click="ceshi">ceshi</el-button> -->
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="用户姓名/电话">
					<template slot-scope="scope">
						<div>{{scope.row.user_name}} / {{scope.row.user_tel}}</div>
						<div>{{scope.row.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="支付审核">
					<template slot-scope="scope" v-if="scope.row.shipper_set_info">
						<el-switch @change="tab_switch_change($event,scope.row.user_num,'is_need_pay_check')" v-model="need_pay_check[scope.row.user_num]"></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="自动提交开票">
					<template slot-scope="scope" v-if="scope.row.shipper_set_info">
						<el-switch @change="tab_switch_change($event,scope.row.user_num,'is_auto_invoice_apply')" v-model="auto_invoice_apply[scope.row.user_num]"></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="自动申请结算">
					<template slot-scope="scope" v-if="scope.row.shipper_set_info">
						<el-switch @change="tab_switch_change($event,scope.row.user_num,'is_auto_settle_apply')" v-model="auto_settle_apply[scope.row.user_num]"></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="额外服务费率">
					<template slot-scope="scope" v-if="scope.row.shipper_set_info">
						<el-input v-model="extra_service_charge_rate[scope.row.user_num]">
							<el-button slot="append" @click="extra_sc_rate_sub(scope.row.user_num)">修改</el-button>
						</el-input>
					</template>
				</el-table-column>
				<el-table-column label="额外服务费计算方式">
					<template slot-scope="scope" v-if="scope.row.shipper_set_info">
						<el-radio-group @change="extra_sc_type_change(scope.row.user_num)" v-model="extra_service_charge_type[scope.row.user_num]">
							<el-radio label="1">除法</el-radio>
							<el-radio label="2">乘法</el-radio>
						</el-radio-group>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
				//搜索条件
				form: {
					tel:'',//电话
					id_card_num:'',//身份证号
					name:'',//公司名称
					license_code:'',//是否可用
					check_status:'',//身份证号
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				
				//支付审核列表
				need_pay_check:{},
				
				//是否自动提交开票申请(1:是,2:否)
				auto_invoice_apply:{},

				//是否自动提交结算申请(1:是,2:否)
				auto_settle_apply:{},
				
				//额外服务费率
				extra_service_charge_rate:{},
				
				//额外服务费计算方式(1:除法模式,2:乘法模式)
				extra_service_charge_type:{},
			}
		},
		created() {

			//读取页面数据
			this.get_page_data()
		},
		methods: {
			
			//修改额外服务费率计算方式
			extra_sc_type_change(user_num){
				
				let new_sc_type=this.extra_service_charge_type[user_num]
				if(['1','2'].indexOf(new_sc_type)==-1){
					this.$my.other.msg({
						type:'warning',
						str:'请刷新后再试'
					});
					return;
				}
				
				//提交
				this.edit_sub({
					user_num:user_num,
					extra_service_charge_type:new_sc_type,
				});
			},
			
			//提交修改额外服务费率设置
			extra_sc_rate_sub(user_num){
				
				let new_sc_rate=this.extra_service_charge_rate[user_num]
				if(!this.$my.check.is_num(new_sc_rate)){
					this.$my.other.msg({
						type:'warning',
						str:'额外服务费率只能是数字'
					});
					return;
				}
				if(new_sc_rate<5||new_sc_rate>9){
					this.$my.other.msg({
						type:'warning',
						str:'新的额外服务费率数据不合规,取值范围(5.00-9.00)'
					});
					return;
				}

				//提交
				this.edit_sub({
					user_num:user_num,
					extra_service_charge_rate:new_sc_rate,
				});
			},

			//表格内开关
			tab_switch_change(rel,user_num,key){
				
				let edit_para={
					user_num:user_num,
				}
				edit_para[key]=rel?1:2;
				this.edit_sub(edit_para);
			},

			//提交修改
			edit_sub(obj){
				
				//提交
				this.$my.net.req({
					data:{
						mod:'truck_other',
						ctr:'shipper_extend_edit_admin',
						...obj
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});
						this.get_page_data()
					},
				});
			},

			//搜索
			ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_other',
						ctr:'shipper_extend_list_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							item.pass_time_text=item.pass_time>0?this.$my.other.totime(item.pass_time):"尚未通过";
							
							//如果存在货主设置数据
							if(item.shipper_set_info){

								//是否需要支付审核
								this.$set(this.need_pay_check,item.user_num,item.shipper_set_info.is_need_pay_check==1?true:false)
								
								//是否自动提交开票申请(1:是,2:否)
								this.$set(this.auto_invoice_apply,item.user_num,item.shipper_set_info.is_auto_invoice_apply==1?true:false)

								//是否自动提交结算申请(1:是,2:否)
								this.$set(this.auto_settle_apply,item.user_num,item.shipper_set_info.is_auto_settle_apply==1?true:false)
								
								//额外服务费率
								this.$set(this.extra_service_charge_rate,item.user_num,item.shipper_set_info.extra_service_charge_rate)
								
								//额外服务费计算方式(1:除法模式,2:乘法模式)
								this.$set(this.extra_service_charge_type,item.user_num,item.shipper_set_info.extra_service_charge_type)
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//弹出层
	.in_tab_img_item{
		width: 300px;
	}

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 100px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>